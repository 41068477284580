


























































































































































































































































































































































































































































































































































































































































































































	import { Component, Vue } from 'vue-property-decorator'
	import '@/assets/themes/prism-darcula.css'

	@Component
	export default class Errors extends Vue { 
    private version = "v2";
   private includeDescription= false;
	}
